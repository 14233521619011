export const GENERAL_COUNSEL_TESTIMONIALS = [
	{
		text: "Adrian Pandev is a great lawyer. He is one of those rare lawyers that doe not only work for money. He really cares about people and helping them. If you are desperate and do not know what do just contact him you'll see for yourself",
		name: 'Shafagh K',
		stars: 5,
	},
	{
		text: 'Adrian and his team consistently provided my digital startup company with amazing representation, from company formation to drafting various critical contracts and agreements. Adrian also provided me with important advice on international deals and U.S. immigration compliance. He took away the stress of legal work off my head and asked me to focus on my business.Adrian is always a pleasure to work with and truly cares about your business as he does about your legal issues. ',
		name: 'Anshul Augnivansh',
		stars: 5,
	},
	{
		text: 'I had found them on Google and turns out they truly deserved all the 5 stars they were given. Not only they are attentive and diligent but also very knowledgeable.',
		name: 'Efecan Atilgan',
		stars: 5,
	},
	{
		text: 'Working with Adrian was nothing short of an excellent experience. His attention to detail and quick responses to questions is couple of the many great qualities about him.\nI would highly recommend him to anyone needing his professional expertise.',
		name: 'Pank Vij',
		stars: 5,
	},
	{
		text: 'Adrian did a great job. I was very happy with his work and he was very thorough. I highly recommend him.',
		name: 'Jordan Slotnick',
		stars: 5,
	},
	{
		text: 'You will be hard-pressed to find any service that is better than Pandev Law. They are extremely professional - the website is easy to navigate while packed with valuable information, you can easily schedule a call, and everyone is very reassuring, helpful, and responsive. Most importantly, they are able to get things done extremely efficient - it took them just about a week to get my LLC all up and running. I highly recommend them!',
		name: 'Khanh Nguyen',
		stars: 5,
	},
	{
		text: "Just the nicest attorney I've met. Very straightforward and makes every effort to help his client. Would recommend 10/10.",
		name: 'Dinesh Arjun',
		stars: 5,
	},
	{
		text: 'Adrian is a knowledgeable and professional lawyer who makes time for his clients and offer them the best advice. I highly recommend his professional services.',
		name: 'Shoaib Iqbal',
		stars: 5,
	},
	{
		text: 'Great services. Efficient, professional, and friendly. Highly recommend',
		name: 'Phil Frayssinoux',
		stars: 5,
	},
];
