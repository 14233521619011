import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import get from 'lodash.get';
import Layout from '../components/layout';
import Hero from '../components/common/hero';
import heroImg from '../assets/img/general-councel/general-counsel-services-hero.jpg';
import CtaDeck from '../components/common/cta-deck';
import SuccessStoriesDeck from '../components/common/Success-stories-deck';
import PilarNavigation from '../components/pilar/pilar-navigation';
import { GENERAL_COUNSEL_TESTIMONIALS } from '../data/general-counsel-services/testimonials';
import AbTestingDeck from '../components/ab-testing/ab-testing-deck';
import CategorySlider from '../components/blog/category-slider';
import Reviewer from '../components/common/reviewer';
import Signature from '../components/common/signature';
import { prependStaticURL } from '../utils';

const headMeta = {
  title: 'General Counsel Services | Pandev Law',
  description:
    'As your outside general counsel, Pandev Law can handle your legal issues while you focus on scaling your business. Click to learn more.',
  keywords:
    'investor immigration,investor visa USA,entrepreneur visa USA,US green card by investment',
};

const schema = `[
  {
    "@context": "http://schema.org",
    "@type": "Product",
    "name": "General Counsel Services | Pandev Law",
    "description": "As your outside general counsel, Pandev Law can handle your legal issues while you focus on scaling your business. Click to learn more.",
    "url": "https://www.pandevlaw.com/general-counsel-services",
    "image": "https://pandevlaw.com/img/general-counsel-services/general-counsel-services-hero.jpg",
    "brand": "Pandev Law",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "5",
      "bestRating": "5",
      "worstRating": "0",
      "ratingCount": "246"
    },
    "sameAs": [
      "https://en-gb.facebook.com/pandevlaw/"
    ]
  },
  {
  "@context": "https://schema.org",
  "@type": "Article",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://www.pandevlaw.com/general-counsel-services/"
  },
  "headline": "Outside General Counsel",
  "description": ${JSON.stringify(headMeta.description)},
  "image": ${prependStaticURL(heroImg)},  
  "author": {
    "@type": "Person",
    "name": "Adrian Pandev",
    "url": "https://www.pandevlaw.com/about/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Pandev Law",
    "logo": {
      "@type": "ImageObject",
      "url": "https://www.pandevlaw.com/static/13437dd4aac9a31bf6176b69797f47be/61fd1/logo.webp"
    }
  },
  "datePublished": ""
}]`;

function GeneralCounselServices({ location, data }) {
  const { posts } = data;
  const category = get(data, 'category', null);

  return (
    <Layout headMeta={headMeta} location={location} footerSchema={schema}>
      <Hero
        title="Outside General Counsel"
        img={heroImg}
        imgAlt="General Counsel Services">
        <p className="text-lead mb-5 mb-md-6">
          Having access to on-going legal advice from your outside general counsel who
          understands your business is invaluable. If you are running a startup or small
          company, you know that legal questions arise frequently and require answers
          quickly. However, operating an in-house legal department is rarely an effective
          solution unless you are a medium or large company. As your outside general
          counsel, Pandev Law can handle your legal issues while you focus on scaling your
          business. If your company already has an existing legal department, our
          attorneys are available to supplement your existing legal team with our
          extensive experience in our specialized practice areas.
        </p>
      </Hero>

      <AbTestingDeck
        position="top"
        title="General Counsel Testimonials"
        testimonials={GENERAL_COUNSEL_TESTIMONIALS}
      />

      <div className="section pt-5 pb-5 pt-md-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/general-councel/value-of-general-counsel-services.jpg"
                alt="Value of General Counsel Services"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <Reviewer />
              <h2 className="text-headline mb-5 mb-md-6">
                Value of General Counsel Services
              </h2>
              <p className="text-body mb-6">
                Pandev Law serves as outside general counsel to companies nationwide. We
                tailor our general counsel services to clients’ needs, objectives, and
                budget. Our goal is to become an intrinsic part of client operations by
                providing trusted outside legal counsel to management on an ongoing and
                cost-effective basis. We work closely with clients, developing a thorough
                understanding of business objectives, models, and motivations. Our
                long-term involvement and in-depth understanding of your business allows
                us to identify important legal issues before they escalate into problems.
                We believe in a proactive approach that mitigates risks and focuses on
                business growth. Our attorneys are available for counsel whenever and as
                much as your business requires. Our goal is to provide you with on-going
                access to sophisticated counsel experienced in devising legal solutions
                for complex, dynamic business environments.
              </p>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/general-councel/cost-benefit-of-general-counsel-servises.jpg"
                alt="Cost Benefit of General Counsel Services"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">
                Cost Benefit of General Counsel Services
              </h2>
              <p className="text-body mb-6">
                Due to the on-going, long-term nature of our outside general counsel
                relationships, we are able to provide our general counsel services at a
                fraction of our regular hourly rates and significantly below the cost of
                hiring a full-time in-house attorney. The cost and scope of our outside
                general counsel services are tailored to your needs and budget. We
                generally charge a fixed fee which is paid monthly, but have also had
                clients purchase blocks of time in advance that were used on an as-needed
                basis. The cost-effective access to on-going, trusted outside legal
                counsel offered by our general counsel services helps clients make
                important business decisions with confidence which positively impacts
                their businesses.
              </p>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/general-councel/general-counsel-services.jpg"
                alt="General Counsel Services Overview"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">General Counsel Services</h2>
              <p className="text-body mb-5">
                Our outside general counsel services are always tailored to clients’
                needs. As an outside general counsel client, you have access to the full
                range of legal solutions offered by all of our practice groups. As your
                outside legal counsel, we offer a broad-range of legal services,
                including:
                <br />
                <br />
                <ul className="list-bullets">
                  <li>Corporate governance and internal compliance</li>
                  <li>Participation in board, shareholder, and special meetings</li>
                  <li>General day-to-day business operation compliance</li>
                  <li>Contract review, drafting, and negotiation</li>
                  <li>Business registration and licensing</li>
                  <li>Intellectual property transactions</li>
                  <li>Privacy, data security, and online transactions</li>
                  <li>Mergers and acquisitions</li>
                  <li>Equity and debt financing transactions</li>
                  <li>Due diligence matters</li>
                  <li>Employment matters</li>
                  <li>Immigration matters</li>
                  <li>Access to wide network of professionals worldwide</li>
                </ul>
                <br />
                Whether you have general business related questions, are considering
                starting a new venture, deciding on whether to buy or sell a business,
                wondering how to set up a stock option pool for your employees, seeking
                venture capital, or need representation in drafting and negotiating a
                contract, Pandev Law acts as your experienced outside general counsel. We
                help you achieve your business objectives and protect you from unnecessary
                risks.
              </p>
            </div>
          </div>
          <Signature />
        </div>
      </div>
      {get(category, 'posts.nodes.length', 0) > 0 && (
        <>
          <hr className="my-8" />
          <CategorySlider index={2} category={category} />
        </>
      )}
      <AbTestingDeck
        title="General Counsel Testimonials"
        testimonials={GENERAL_COUNSEL_TESTIMONIALS}
      />
      <PilarNavigation />
      <CtaDeck />
      <SuccessStoriesDeck posts={posts} />
    </Layout>
  );
}

export const query = graphql`
  query GeneralCounselServicesPageQuery {
    category: wpCategory(slug: { eq: "general-counsel" }) {
      ...BlogLandingHeroPost
    }
    posts: allWpPost(
      limit: 9
      sort: { date: DESC }
      filter: {
        categories: { nodes: { elemMatch: { databaseId: { eq: 4 } } } }
        slug: {
          in: [
            "seed-round-corporate-work-216"
            "sale-of-business-valued-at-6mm-232"
            "series-seed-financing-for-1-25mm-256"
            "purchase-of-controlling-interest-worth-350k-250"
            "startup-initial-organization-252"
            "pre-seed-corporate-round-work-242"
            "startup-initial-organization-244"
            "seed-round-corporate-work-262"
            "foreign-direct-investment-of-1mm-198"
          ]
        }
      }
    ) {
      edges {
        node {
          id: databaseId
          title
          date
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              altText
            }
          }
          slug
          excerpt
        }
      }
    }
  }
`;

export default GeneralCounselServices;
